<template>
  <tr>
    <td
      v-for="header in duplicate.filter(header => header.align !== ' d-none')"
      class="px-3"
      :key="header.value"
      scope="col"
    >
      <div
        v-if="!header.hideFilter"
      >
        <control-select
          v-if="header.type === 'boolean'"
          v-model="header.filterText"
          :items="BooleanFilterItems(header.nullable)"
          itemText="text"
          itemValue="value"
          :readonly="false"
        />
        <v-text-field
          v-else
          v-model="header.filterText"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import ControlSelect from './ControlSelect.vue'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'FilterHeader',
  components: {
    ControlSelect
  },
  props: {
    headers: Array,
    list: Array
  },
  data () {
    return {
      booleanFilterItems: [
        {
          text: 'Aangevinkt',
          value: 'true'
        },
        {
          text: 'Niet aangevinkt',
          value: 'false'
        },
        {
          nullable: true,
          text: 'Onbepaald',
          value: 'null'
        }
      ],
      duplicate: []
    }
  },
  mounted () {
    this.SetDuplicate()
  },
  computed: {
    List () {
      let filteredList = this.HardCopy(this.list)

      this.duplicate.forEach(header => {
        if (header.filterText && header.filterText.toString().trim() !== '') {
          filteredList = this.Filter(filteredList, header.value, header.filterText.toString().trim(), header.type)
        }
      })

      return filteredList
    }
  },
  watch: {
    headers () {
      this.SetDuplicate()
    },
    List () {
      this.$emit('input', this.List)
    }
  },
  methods: {
    BooleanFilterItems (nullable) {
      return nullable ? this.booleanFilterItems : this.booleanFilterItems.filter(item => !item.nullable) ?? []
    },
    Filter (list, propertyName, filterValue, type) {
      return list.filter(item => {
        const property = this.GetFilterProperty(item, propertyName)

        if (type === 'boolean') {
          const value = filterValue === '' ? null : filterValue
          return (property != null || filterValue === 'null') && (property ?? 'null').toString() === value
        } else if (type === 'date') {
          return property != null && this.FormatDateTime(property, 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY HH:mm:ss').includes(filterValue)
        } else if (type === 'decimal') {
          return property != null && this.FormatDecimal(property.toString().toLowerCase(), 2, false).includes(filterValue.toLowerCase())
        } else {
          return property != null && property.toString().toLowerCase().includes(filterValue.toLowerCase())
        }
      })
    },
    FormatDateTime (dateTime, oldFormat, newFormat) {
      if (dateTime && dateTime.trim() !== '') {
        return moment(dateTime, oldFormat).format(newFormat)
      } else {
        return null
      }
    },
    FormatDecimal (value, digits = 2, showZero = true) {
      if (value || showZero) {
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'decimal',
          maximumFractionDigits: digits,
          minimumFractionDigits: digits
        })

        return formatter.format(value ?? 0)
      } else {
        return null
      }
    },
    GetFilterProperty (item, propertyName) {
      const properties = propertyName.split('.')
      let current = item

      properties.forEach(property => {
        if (current != null) {
          current = current[property]
        }
      })

      return current
    },
    HardCopy (source) {
      let clone = _.clone(source)

      if (source != null) {
        if (Array.isArray(source)) {
          clone = []
          for (let index = 0; index < source.length; index++) {
            clone[index] = this.HardCopy(source[index])
          }
        } else if (typeof (source) === 'object') {
          const keys = (Object.keys(source))

          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            clone[key] = this.HardCopy(source[key])
          }
        }
      }

      return clone
    },
    SetDuplicate () {
      this.duplicate = this.HardCopy(this.headers)
    }
  }
}
</script>
