<template>
  <div class="pa-5">
    <v-btn class="primary" @click="GetGeoMaps(-1, true)"
      :loading="LoadingMaps">Laad Kaarten</v-btn>
    <v-row>
      <v-col
        v-for="(map, index) in MapCount"
        :key="index"
      >
        <v-slider
          :disabled="LoadingMaps"
          max="2.5"
          min="0.5"
          step="0.5"
          v-model="zoom[GetMapNumberProperty(map)]"
          @input="Zoom()"
          style="width: 250px"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @click:append="zoomIn(map)"
          @click:prepend="zoomOut(map)"
        ></v-slider>
        <div v-if="!loadingMap[GetMapNumberProperty(map)] && mapimage[GetMapNumberProperty(map)] && mapimage[GetMapNumberProperty(map)] !== 'error'">
          <img :src="mapimage[GetMapNumberProperty(map)]" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else-if="mapimage[GetMapNumberProperty(map)] === 'error'">
          <img src="../assets/images/blank-map-not-found.png" width="256" height="256" class="ma-1"/>
        </div>
        <div v-else>
          <v-progress-circular
            v-if="loadingMap[GetMapNumberProperty(map)]"
            :size="50"
            color="primary"
            style="position: absolute; margin: 10px"
            indeterminate
          ></v-progress-circular>
          <img src="../assets/images/blank-map.png" width="256" height="256" class="ma-1"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import constantMixin from '../mixins/constantMixin'
import geoService from '../services/geoService'

export default {
  mixins: [
    constantMixin
  ],
  props: {
    straat: String,
    huisnummer: String,
    gemeente: String,
    sliderZoom1: Number,
    sliderZoom2: Number,
    sliderZoom3: Number,
    sliderZoom4: Number,
    sliderZoom5: Number,
    sliderZoom6: Number
  },
  data () {
    return {
      postcodes: null,
      location: {
        X_lambert72: 0,
        Y_lambert72: 0
      },
      mapimages: {
        map1: null,
        map2: null,
        map3: null,
        map4: null,
        map5: null,
        map6: null
      },
      mapimage: {
        map1: null,
        map2: null,
        map3: null,
        map4: null,
        map5: null,
        map6: null
      },
      loadingMap: {
        map1: false,
        map2: false,
        map3: false,
        map4: false,
        map5: false,
        map6: false
      },
      zoom: {
        map1: this.sliderZoom1,
        map2: this.sliderZoom2,
        map3: this.sliderZoom3,
        map4: this.sliderZoom4,
        map5: this.sliderZoom5,
        map6: this.sliderZoom6
      }
    }
  },
  computed: {
    LoadingMaps () {
      return Object.values(this.loadingMap).every(loadingMap => loadingMap)
    },
    MapCount () {
      return Object.keys(this.zoom).length
    }
  },
  mounted () {
    geoService.Init(this)
    this.GetGeoMaps(-1)
  },
  watch: {
    sliderZoom1 (value) {
      this.HandleSliderZoomChange(1, value)
    },
    sliderZoom2 (value) {
      this.HandleSliderZoomChange(2, value)
    },
    sliderZoom3 (value) {
      this.HandleSliderZoomChange(3, value)
    },
    sliderZoom4 (value) {
      this.HandleSliderZoomChange(4, value)
    },
    sliderZoom5 (value) {
      this.HandleSliderZoomChange(5, value)
    },
    sliderZoom6 (value) {
      this.HandleSliderZoomChange(6, value)
    },
    'zoom.map1' () {
      this.GetGeoMaps(1)
    },
    'zoom.map2' () {
      this.GetGeoMaps(2)
    },
    'zoom.map3' () {
      this.GetGeoMaps(3)
    },
    'zoom.map4' () {
      this.GetGeoMaps(4)
    },
    'zoom.map5' () {
      this.GetGeoMaps(5)
    },
    'zoom.map6' () {
      this.GetGeoMaps(6)
    }
  },
  methods: {
    GetGeoMaps (map = -1, forceReload = false) {
      if (this.straat && this.gemeente && this.huisnummer) {
        if (map === -1) {
          for (let i = 1; i <= this.MapCount; i++) {
            this.loadingMap[this.GetMapNumberProperty(i)] = true
            this.mapimage[this.GetMapNumberProperty(i)] = null
          }
          this.GetGeoMapGRB(forceReload)
          this.GetGeoMap2GRB(forceReload)
          this.GetGeoMapGewestplan(forceReload)
          this.GetGeoMapLuchtfoto(forceReload)
          this.GetGeoMapPluviaal(forceReload)
          this.GetGeoMapFluviaal(forceReload)
        } else {
          this.loadingMap[this.GetMapNumberProperty(map)] = true
          this.mapimage[this.GetMapNumberProperty(map)] = null
          switch (map) {
            case 1:
              this.GetGeoMapGRB(forceReload)
              break
            case 2:
              this.GetGeoMap2GRB(forceReload)
              break
            case 3:
              this.GetGeoMapGewestplan(forceReload)
              break
            case 4:
              this.GetGeoMapLuchtfoto(forceReload)
              break
            case 5:
              this.GetGeoMapPluviaal(forceReload)
              break
            case 6:
              this.GetGeoMapFluviaal(forceReload)
              break
          }
        }
      }
    },
    async GetGeoMap (forceReload, number, mapType) {
      if (this.straat && this.huisnummer && this.gemeente) {
        const image = !this.mapimages[this.GetMapNumberProperty(number)] || forceReload ? await geoService.GetGeoMapGRB(this.straat, this.huisnummer, this.gemeente, 300, 300, mapType) : null

        if (image?.data || this.mapimages[this.GetMapNumberProperty(number)]) {
          this.mapimages[this.GetMapNumberProperty(number)] ??= image.data.images
          this.mapimages[this.GetMapNumberProperty(number)] = forceReload ? image.data.images : this.mapimages[this.GetMapNumberProperty(number)]

          if (this.mapimages[this.GetMapNumberProperty(number)]) {
            const mapimageElement = this.mapimages[this.GetMapNumberProperty(number)].find(element => element.scale === 3 - this.zoom[this.GetMapNumberProperty(number)])

            if (mapimageElement) {
              this.mapimage[this.GetMapNumberProperty(number)] = mapimageElement.base64
            } else {
              this.mapimage[this.GetMapNumberProperty(number)] = 'error'
            }
          } else {
            this.mapimage[this.GetMapNumberProperty(number)] = 'error'
          }

          this.loadingMap[this.GetMapNumberProperty(number)] = false
        }
      }
    },
    async GetGeoMapGRB (forceReload) {
      await this.GetGeoMap(forceReload, 1, this.constantMixin.mapType.google_stratenplan)
    },
    async GetGeoMap2GRB (forceReload) {
      await this.GetGeoMap(forceReload, 2, this.constantMixin.mapType.grb_bsk)
    },
    async GetGeoMapGewestplan (forceReload) {
      await this.GetGeoMap(forceReload, 3, this.constantMixin.mapType.gewestplan)
    },
    async GetGeoMapLuchtfoto (forceReload) {
      await this.GetGeoMap(forceReload, 4, this.constantMixin.mapType.google_luchtfoto)
    },
    async GetGeoMapPluviaal (forceReload) {
      await this.GetGeoMap(forceReload, 5, this.constantMixin.mapType.pluviaal)
    },
    async GetGeoMapFluviaal (forceReload) {
      await this.GetGeoMap(forceReload, 6, this.constantMixin.mapType.fluviaal)
    },
    GetMapNumberProperty (number) {
      return `map${number}`
    },
    HandleSliderZoomChange (number, value) {
      const property = this.GetMapNumberProperty(number)
      this.zoom[property] = value
      if (this.mapimages[property]) {
        var mapimage = this.mapimages[property].find(element => element.scale === value)
        if (mapimage) {
          this.mapimage[property] = mapimage.base64
        }
      }
    },
    Zoom () {
      this.$emit('zoom', {
        zoom1: this.zoom.map1,
        zoom2: this.zoom.map2,
        zoom3: this.zoom.map3,
        zoom4: this.zoom.map4,
        zoom5: this.zoom.map5,
        zoom6: this.zoom.map6
      })
    },
    zoomIn (map) {
      const property = this.GetMapNumberProperty(map)
      this.zoom[property] += this.zoom[property] < 2.5 ? 0.5 : 0
      this.Zoom()
    },
    zoomOut (map) {
      const property = this.GetMapNumberProperty(map)
      this.zoom[property] -= this.zoom[property] > 0.5 ? 0.5 : 0
      this.Zoom()
    }
  }
}
</script>
