<template>
    <div>
        <v-card
            class="ma-3">
            <v-card-title>Parameters
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    label="Zoeken"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>

            <v-data-table
                dense
                :headers="headers"
                :items="constants"
                :group-by="groupedBy"
                :show-expand="false"
                :search="searchText"
                :items-per-page="-1"
            >
            </v-data-table>
        </v-card>
      </div>
</template>

<script>
export default {
  data () {
    return {
      searchText: '',
      groupedBy: 'Groep', // Dit wordt de groepnaam (region) in jouw geval
      headers: [
        { text: 'Parameter Word-document', value: 'Constant' },
        { text: 'Beschrijving', value: 'Description' }
      ],
      constants: [
        { Groep: 'Schatting', Constant: '@SCHATTER_NAAM@', Description: 'Schatter naam' },
        { Groep: 'Schatting', Constant: '@SCHATTER_VOORNAAM@', Description: 'Schatter voornaam' },
        { Groep: 'Schatting', Constant: '@SCHATTER_MAIL@', Description: 'Schatter mail' },
        { Groep: 'Schatting', Constant: '@SCHATTER_LAN@', Description: 'Schatter LAN-nummer' },
        { Groep: 'Schatting', Constant: '@SCHATTER_VLABEL@', Description: 'Schatter Vlabel-nummer' },
        { Groep: 'Schatting', Constant: '@PROJECTVERANTWOORDELIJKE_NAAM@', Description: 'Projectverantwoordelijke naam' },
        { Groep: 'Schatting', Constant: '@PROJECTVERANTWOORDELIJKE_VOORNAAM@', Description: 'Projectverantwoordelijke voornaam' },
        { Groep: 'Schatting', Constant: '@PROJECTVERANTWOORDELIJKE_MAIL@', Description: 'Projectverantwoordelijke mail' },
        { Groep: 'Schatting', Constant: '@PROJECTVERANTWOORDELIJKE_LAN@', Description: 'Projectverantwoordelijke LAN-nummer' },
        { Groep: 'Schatting', Constant: '@PROJECTVERANTWOORDELIJKE_VLABEL@', Description: 'Projectverantwoordelijke Vlabel-nummer' },
        { Groep: 'Schatting', Constant: '@AFB_PROJECTVERANTWOORDELIJKE_HANDTEKENING@', Description: 'Afbeelding projectverantwoordelijke handtekening' },
        { Groep: 'Schatting', Constant: '@BEMIDDELAAR@', Description: 'Bemiddelaar' },
        { Groep: 'Schatting', Constant: '@BANK@', Description: 'Bank' },
        { Groep: 'Schatting', Constant: '@STATUS@', Description: 'Status' },
        { Groep: 'Schatting', Constant: '@DATUM_PLAATSBEZOEK@', Description: 'Datum plaatsbezoek' },
        { Groep: 'Schatting', Constant: '@DATUM_OPDRACHT@', Description: 'Datum opdracht' },
        { Groep: 'Schatting', Constant: '@DATUM_OPMAAK_VERSLAG@', Description: 'Datum opmaak verslag' },
        { Groep: 'Schatting', Constant: '@OPDRACHT@', Description: 'Opdracht' },

        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_NAAM@', Description: 'Ontlener naam' },
        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_VOORNAAM@', Description: 'Ontlener voornaam' },
        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_STRAAT@', Description: 'Ontlener straat' },
        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_NR@', Description: 'Ontlener nummer' },
        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_GEMEENTE@', Description: 'Ontlener gemeente' },
        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_TEL@', Description: 'Ontlener telefoonnummer' },
        { Groep: 'Algemene Gegevens', Constant: '@ONTLENER_MAIL@', Description: 'Ontlener e-mailadres' },
        { Groep: 'Algemene Gegevens', Constant: '@AARD@', Description: 'Aard van het pand' },
        { Groep: 'Algemene Gegevens', Constant: '@LIGGING_STRAAT@', Description: 'Ligging op straat' },
        { Groep: 'Algemene Gegevens', Constant: '@LIGGING_NR@', Description: 'Ligging nummer' },
        { Groep: 'Algemene Gegevens', Constant: '@LIGGING_GEMEENTE@', Description: 'Ligging gemeente' },
        { Groep: 'Algemene Gegevens', Constant: '@KADASTRALE_AFDELING@', Description: 'Kadastrale afdeling' },
        { Groep: 'Algemene Gegevens', Constant: '@KADASTRALE_SECTIE@', Description: 'Kadastrale sectie' },
        { Groep: 'Algemene Gegevens', Constant: '@KADASTRALE_NUMMERS@', Description: 'Kadastrale nummers' },
        { Groep: 'Algemene Gegevens', Constant: '@KADASTRAAL_INKOMEN@', Description: 'Kadastraal inkomen' },
        { Groep: 'Algemene Gegevens', Constant: '@AFB_GEGEVENS@', Description: 'Afbeeldingen gegevens' },
        { Groep: 'Algemene Gegevens', Constant: '@KAART_STRATENPLAN@', Description: 'Kaart stratenplan' },
        { Groep: 'Algemene Gegevens', Constant: '@KAART_LUCHTFOTO@', Description: 'Kaart luchtfoto' },
        { Groep: 'Algemene Gegevens', Constant: '@KAART_GRB@', Description: 'Kaart GRB' },
        { Groep: 'Algemene Gegevens', Constant: '@KAART_GEWEST@', Description: 'Kaart gewest' },
        { Groep: 'Algemene Gegevens', Constant: '@KAART_FLUVIAAL@', Description: 'Kaart fluviaal' },
        { Groep: 'Algemene Gegevens', Constant: '@KAART_PLUVIAAL@', Description: 'Kaart pluviaal' },
        { Groep: 'Algemene Gegevens', Constant: '@BEBOUWING@', Description: 'Bebouwing' },

        { Groep: 'Afmetingen', Constant: '@PERCEEL_B@', Description: 'Perceel breedte' },
        { Groep: 'Afmetingen', Constant: '@PERCEEL_OPP@', Description: 'Perceel oppervlakte' },
        { Groep: 'Afmetingen', Constant: '@VOORGEVEL_B@', Description: 'Voorgevel breedte' },
        { Groep: 'Afmetingen', Constant: '@OPP_KELDER@', Description: 'Oppervlakte kelder' },
        { Groep: 'Afmetingen', Constant: '@OPP_GELIJKVLOERS@', Description: 'Oppervlakte gelijkvloers' },
        { Groep: 'Afmetingen', Constant: '@OPP_VERDIEPING_1@', Description: 'Oppervlakte verdieping 1' },
        { Groep: 'Afmetingen', Constant: '@OPP_VERDIEPING_2@', Description: 'Oppervlakte verdieping 2' },
        { Groep: 'Afmetingen', Constant: '@OPP_VERDIEPING_3@', Description: 'Oppervlakte verdieping 3' },
        { Groep: 'Afmetingen', Constant: '@OPP_DAK@', Description: 'Oppervlakte dak' },
        { Groep: 'Afmetingen', Constant: '@OPP_BIJGEBOUW@', Description: 'Oppervlakte bijgebouw' },
        { Groep: 'Afmetingen', Constant: '@AFMETING_EXTRA@', Description: 'Afmeting extra' },
        { Groep: 'Afmetingen', Constant: '@AFB_AFMETINGEN@', Description: 'Afbeeldingen afmetingen' },

        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_KELDER@', Description: 'Beschrijving kelder' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_GELIJKVLOERS@', Description: 'Beschrijving gelijkvloers' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_VERDIEPING_1@', Description: 'Beschrijving verdieping 1' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_VERDIEPING_2@', Description: 'Beschrijving verdieping 2' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_VERDIEPING_3@', Description: 'Beschrijving verdieping 3' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_DAK@', Description: 'Beschrijving dak' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_BIJGEBOUW@', Description: 'Beschrijving bijgebouw' },
        { Groep: 'Beschrijving', Constant: '@BESCHRIJVING_EXTRA@', Description: 'Beschrijving extra' },
        { Groep: 'Beschrijving', Constant: '@AFB_BESCHRIJVING@', Description: 'Afbeeldingen beschrijving' },

        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_METSELWERK@', Description: 'Bouwmateriaal metselwerk' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_DAK@', Description: 'Bouwmateriaal dak' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_DAKBEDEKKING@', Description: 'Bouwmateriaal dakbedekking' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_SCHRIJNWERK@', Description: 'Bouwmateriaal schrijnwerk' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_BEGLAZING@', Description: 'Bouwmateriaal beglazing' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_ISOLATIE@', Description: 'Bouwmateriaal isolatie' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_FUNDERINGEN@', Description: 'Bouwmateriaal funderingen' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_VLOEREN@', Description: 'Bouwmateriaal vloeren' },
        { Groep: 'Bouwmateriaal', Constant: '@BOUWMATERIAAL_AFWERKING@', Description: 'Bouwmateriaal afwerking' },
        { Groep: 'Bouwmateriaal', Constant: '@EPC@', Description: 'Energieprestatiecertificaat (EPC)' },
        { Groep: 'Bouwmateriaal', Constant: '@ELECTRICITEIT_CONFORM@', Description: 'Electriciteit conform' },
        { Groep: 'Bouwmateriaal', Constant: '@ASBESTINVENTARIS@', Description: 'Asbestinventaris' },
        { Groep: 'Bouwmateriaal', Constant: '@AFB_BOUWMATERIAAL@', Description: 'Afbeeldingen bouwmateriaal' },

        { Groep: 'Nutsvoorzieningen & Comfort', Constant: '@NUTSVOORZIENINGEN@', Description: 'Nutsvoorzieningen' },
        { Groep: 'Nutsvoorzieningen & Comfort', Constant: '@VERWARMINGSSYSTEEM@', Description: 'Verwarmingssysteem' },
        { Groep: 'Nutsvoorzieningen & Comfort', Constant: '@COMFORT@', Description: 'Comfort' },
        { Groep: 'Nutsvoorzieningen & Comfort', Constant: '@SANITAIR_BADKAMER@', Description: 'Sanitair badkamer' },
        { Groep: 'Nutsvoorzieningen & Comfort', Constant: '@SANITAIR_KEUKEN@', Description: 'Sanitair keuken' },
        { Groep: 'Nutsvoorzieningen & Comfort', Constant: '@AFB_NUTCOMFORT@', Description: 'Afbeeldingen nutsvoorzieningen en comfort' },

        { Groep: 'Bijzondere Elementen', Constant: '@BOUWJAAR_VAN@', Description: 'Bouwjaar (van)' },
        { Groep: 'Bijzondere Elementen', Constant: '@BOUWJAAR_TOT@', Description: 'Bouwjaar (tot)' },
        { Groep: 'Bijzondere Elementen', Constant: '@RENOVATIEJAAR_VAN@', Description: 'Renovatiejaar (van)' },
        { Groep: 'Bijzondere Elementen', Constant: '@RENOVATIEJAAR_TOT@', Description: 'Renovatiejaar (tot)' },
        { Groep: 'Bijzondere Elementen', Constant: '@STAAT_ONDERHOUD_GEBOUW@', Description: 'Staat onderhoud gebouw' },
        { Groep: 'Bijzondere Elementen', Constant: '@STAAT_ONDERHOUD_TUIN@', Description: 'Staat onderhoud tuin' },
        { Groep: 'Bijzondere Elementen', Constant: '@WERKEN_RECENT@', Description: 'Recent uitgevoerde werken' },
        { Groep: 'Bijzondere Elementen', Constant: '@WERKEN_NODIGE_RENOVATIE@', Description: 'Nodige renovatiewerken' },
        { Groep: 'Bijzondere Elementen', Constant: '@WERKEN_GEPLAND_BIJZONDER@', Description: 'Bijzonder kosten, gepland werk' },
        { Groep: 'Bijzondere Elementen', Constant: '@ERFDIENSTBAARHEDEN@', Description: 'Erfdienstbaarheden' },
        { Groep: 'Bijzondere Elementen', Constant: '@GEBRUIK_PAND@', Description: 'Gebruik pand' },
        { Groep: 'Bijzondere Elementen', Constant: '@VERKOOPBAARHEID@', Description: 'Verkoopbaarheid' },
        { Groep: 'Bijzondere Elementen', Constant: '@ENTITEITEN_VOOR_WERKEN_PRIVE@', Description: 'Entiteiten voor werken (privé)' },
        { Groep: 'Bijzondere Elementen', Constant: '@ENTITEITEN_NA_WERKEN_PRIVE@', Description: 'Entiteiten na werken (privé)' },
        { Groep: 'Bijzondere Elementen', Constant: '@HUUR_VOOR_WERKEN_PRIVE@', Description: 'Huur voor werken (privé)' },
        { Groep: 'Bijzondere Elementen', Constant: '@HUUR_NA_WERKEN_PRIVE@', Description: 'Huur na werken (privé)' },
        { Groep: 'Bijzondere Elementen', Constant: '@ENTITEITEN_VOOR_WERKEN_BEROEPS@', Description: 'Entiteiten voor werken (beroeps)' },
        { Groep: 'Bijzondere Elementen', Constant: '@ENTITEITEN_NA_WERKEN_BEROEPS@', Description: 'Entiteiten na werken (beroeps)' },
        { Groep: 'Bijzondere Elementen', Constant: '@HUUR_VOOR_WERKEN_BEROEPS@', Description: 'Huur voor werken (beroeps)' },
        { Groep: 'Bijzondere Elementen', Constant: '@HUUR_NA_WERKEN_BEROEPS@', Description: 'Huur na werken (beroeps)' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_KLANT_GEM@', Description: 'Kostprijs klant gemiddeld' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_AANNEMER_KLANT@', Description: 'Kostprijs aannemer klant' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_EIGEN_KLANT@', Description: 'Kostprijs eigen klant' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_SCHATTER_GEM@', Description: 'Kostprijs schatter gemiddeld' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_AANNEMER_SCHATTER@', Description: 'Kostprijs aannemer schatter' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_EIGEN_SCHATTER@', Description: 'Kostprijs eigen schatter' },
        { Groep: 'Bijzondere Elementen', Constant: '@ASPECT_BETROKKEN_OMGEVING@', Description: 'Aspect betrokken omgeving' },
        { Groep: 'Bijzondere Elementen', Constant: '@LIGGING_TOV_DIENSTVERLENING@', Description: 'Ligging ten opzichte van dienstverlening' },
        { Groep: 'Bijzondere Elementen', Constant: '@VERKEERSMOGELIJKHEDEN_BEREIKBAARHEID@', Description: 'Verkeersmogelijkheden en bereikbaarheid' },
        { Groep: 'Bijzondere Elementen', Constant: '@MOGENLIJKHEDEN_OPENBAAR_VERVOER@', Description: 'Mogelijkheden openbaar vervoer' },
        { Groep: 'Bijzondere Elementen', Constant: '@WEGUITRUSTING@', Description: 'Weguitrusting' },
        { Groep: 'Bijzondere Elementen', Constant: '@MILIEUVRIENDELIJKE_OF_STORENDE_ELEMENTEN@', Description: 'Milieuvriendelijke of storende elementen' },
        { Groep: 'Bijzondere Elementen', Constant: '@AFB_BIJZONDER@', Description: 'Afbeeldingen bijzondere elementen' },
        { Groep: 'Bijzondere Elementen', Constant: '@ENTITEITEN_VOOR_WERKEN_TOTAAL@', Description: 'Entiteiten voor werken (totaal)' },
        { Groep: 'Bijzondere Elementen', Constant: '@ENTITEITEN_NA_WERKEN_TOTAAL@', Description: 'Entiteiten na werken (totaal)' },
        { Groep: 'Bijzondere Elementen', Constant: '@HUUR_VOOR_WERKEN_TOTAAL@', Description: 'Huur voor werken (totaal)' },
        { Groep: 'Bijzondere Elementen', Constant: '@HUUR_NA_WERKEN_TOTAAL@', Description: 'Huur na werken (totaal)' },
        { Groep: 'Bijzondere Elementen', Constant: '@KOSTPRIJS_KLANT_TOTAAL@', Description: 'Kostprijs klant totaal' },
        { Groep: 'Bijzondere Elementen', Constant: '@SCHATTER_KLANT_TOTAAL@', Description: 'Kostprijs schatter totaal' },

        { Groep: 'Waarde', Constant: '@KOSTPRIJS_NIEUW@', Description: 'Kostprijs nieuw' },
        { Groep: 'Waarde', Constant: '@AANTAL_M2@', Description: 'Aantal vierkante meters' },
        { Groep: 'Waarde', Constant: '@KOST_M2@', Description: 'Kostprijs per vierkante meter' },
        { Groep: 'Waarde', Constant: '@VETUSTITEIT@', Description: 'Vetustiteit' },
        { Groep: 'Waarde', Constant: '@AFSCHRIJVINGEN@', Description: 'Afschrijvingen' },
        { Groep: 'Waarde', Constant: '@GRONDWAARDE@', Description: 'Grondwaarde' },
        { Groep: 'Waarde', Constant: '@NIEUWBOUWWAARDE@', Description: 'Nieuwbouwwaarde' },
        { Groep: 'Waarde', Constant: '@VERKOOPWAARDE_ONDERHANDS_VOOR_WERKEN@', Description: 'Verkoopwaarde onderhands voor werken' },
        { Groep: 'Waarde', Constant: '@VERKOOPWAARDE_ONDERHANDS_NA_WERKEN@', Description: 'Verkoopwaarde onderhands na werken' },
        { Groep: 'Waarde', Constant: '@VERKOOPWAARDE_ONDERHANDS_BEROEPS@', Description: 'Verkoopwaarde onderhands percentage beroeps' },
        { Groep: 'Waarde', Constant: '@VERKOOPWAARDE_ONDERHANDS_PRIVE@', Description: 'Verkoopwaarde onderhands percentage privé' },
        { Groep: 'Waarde', Constant: '@VERKOOPWAARDE_GEDWONGEN_VOOR_WERKEN@', Description: 'Verkoopwaarde gedwongen voor werken' },
        { Groep: 'Waarde', Constant: '@VERKOOPWAARDE_GEDWONGEN_NA_WERKEN@', Description: 'Verkoopwaarde gedwongen na werken' },
        { Groep: 'Waarde', Constant: '@GECOMMUNICEERDE_AANKOOPPRIJS@', Description: 'Gecommuniceerde aankoopprijs' },
        { Groep: 'Waarde', Constant: '@ZWAKTES@', Description: 'Zwaktes' },
        { Groep: 'Waarde', Constant: '@STERKTES@', Description: 'Sterktes' },
        { Groep: 'Waarde', Constant: '@OPPORTUNITEITEN@', Description: 'Opportuniteiten' },
        { Groep: 'Waarde', Constant: '@BEDREIGINGEN@', Description: 'Bedreigingen' },
        { Groep: 'Waarde', Constant: '@AFB_WAARDE@', Description: 'Afbeeldingen waarde' },

        { Groep: 'Vergelijkingspunten', Constant: '@VERGELIJKINGSPUNTEN@', Description: 'Vergelijkingspunten' },

        { Groep: 'Mobiscore', Constant: '@MOBISCORE_TOTAAL@', Description: 'Mobiscore totaal' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_OPENBAAR_VERVOER@', Description: 'Mobiscore openbaar vervoer' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_ONDERWIJS@', Description: 'Mobiscore onderwijs' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_WINKELS_EN_DIENSTEN@', Description: 'Mobiscore winkels en diensten' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_ONTSPANNING_SPORT_EN_CULTUUR@', Description: 'Mobiscore ontspanning, sport en cultuur' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_GEZONDHEID_EN_ZORG@', Description: 'Mobiscore gezondheid en zorg' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_MILIEUR_IMPACT@', Description: 'Mobiscore milieu-impact' },
        { Groep: 'Mobiscore', Constant: '@MOBISCORE_NABIJGELEGEN_LOCATIES@', Description: 'Mobiscore nabijgelegen locaties' }
      ]
    }
  }
}
</script>
