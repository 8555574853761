<template>
    <v-form class="my-2"
      v-model="detailMixin.valid"
      ref="formAlgemeen">
      <div
      v-if="detailMixin.element && detailMixin.element.schatting && detailMixin.element.algemeneGegevens"
      :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
      >
        <h2 class="primary--text">Nieuwe schatting</h2>
        <h3 class="secondary--text my-3">Omschrijving opdracht</h3>
        <v-row
          class="pb-0"
        >
          <v-col cols="12" lg="6">
            <option-list
              v-model="detailMixin.element.schatting.opdracht"
              :list="opdrachtTemplates"
              label="Opdracht"
              :rules="[validationMixin.rules.required]"
            />
          </v-col>
        </v-row>
        <h3 class="secondary--text my-3">Verslag opgesteld door</h3>
        <v-row class="pb-0">
          <v-col cols="12" sm="6" lg="3">
            <v-text-field label="Naam"
              :value="detailMixin.element.schatting.gebruikerNaamOmgekeerd"
              readonly />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field label="Email"
              :value="detailMixin.element.schatting.gebruikerEmail"
              readonly />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <control-select
              :items="gebruikers"
              label="Projectverantwoordelijke"
              v-model="detailMixin.element.schatting.idProjectverantwoordelijke"
              itemValue="idGebruiker"
              itemText="naam" />
          </v-col>
        </v-row>
        <v-row class="pb-0">
          <v-col cols="12" sm="6" lg="3">
            <v-text-field label="Bemiddelaar"
              v-model="detailMixin.element.schatting.bemiddelaar"/>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <control-select
              :items="banken"
              label="Bank"
              v-model="detailMixin.element.schatting.idCodeBank"
              :itemValue="'idCode'"
              :itemText="'langeBeschrijving'" />
          </v-col>
        </v-row>
        <v-row class="pb-0">
          <v-col cols="12" sm="6" lg="3">
              <control-date
                v-model="detailMixin.element.schatting.datumPlaatsbezoek"
                label="Datum plaatsbezoek" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <control-date
                v-model="detailMixin.element.schatting.datumOpdracht"
                label="Datum opdracht" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <control-date
                v-model="detailMixin.element.schatting.datumOpmaakVerslag"
                label="Datum opmaken verslag" />
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <h2 class="primary--text my-3">Algemene gegevens</h2>
        <h3 class="secondary--text my-3">Gegevens ontlener</h3>
        <v-row class="pb-0">
          <v-col cols="12" sm="6" lg="3">
              <v-text-field
                label="Voornaam"
                v-model="detailMixin.element.algemeneGegevens.voornaamOntlener" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <v-text-field
                label="Achternaam"
                v-model="detailMixin.element.algemeneGegevens.achternaamOntlener"/>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <v-text-field
                label="Telefoon"
                :prepend-inner-icon="'mdi-phone'"
                v-model="detailMixin.element.algemeneGegevens.telefoonOntlener" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <v-text-field
                label="Email"
                v-model="detailMixin.element.algemeneGegevens.emailOntlener"
                :rules="[validationMixin.rules.email]"
                :prepend-inner-icon="'mdi-email'" />
          </v-col>
          </v-row>
          <v-row class="pb-0">
          <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                v-model="detailMixin.element.algemeneGegevens.straatOntlener"
                label="Straat" />
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="3">
              <v-text-field
                v-model="detailMixin.element.algemeneGegevens.huisnummerOntlener"
                label="Huisnr." />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <control-postcode
                v-on:change="SetPostcodeOntlener"
                v-model="detailMixin.element.algemeneGegevens.idPostcodeOntlener"
                label="Gemeente" />
          </v-col>
        </v-row>
        <h3 class="secondary--text my-3">Aard en ligging van het te schatten goed
          <span class="px-2 ma-0" style="color: grey; font-size: 12px">(*): Deze velden zijn verplicht!</span>
        </h3>
        <v-row class="pb-0">
          <v-col cols="12" sm="6" md="3">
            <option-list
              v-model="detailMixin.element.algemeneGegevens.aardGoed"
              :list="aarden"
              label="*Aard"
              :rules="[validationMixin.rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <control-select
              :items="bebouwingen"
              label="Bebouwing"
              v-model="detailMixin.element.algemeneGegevens.idCodeBebouwing"
              :itemValue="'idCode'"
              :itemText="'langeBeschrijving'" />
          </v-col>
        </v-row>
        <v-row class="pb-0">
          <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                label="*Straat"
                v-model="detailMixin.element.algemeneGegevens.straat"
                :rules="[validationMixin.rules.required]" />
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="3">
              <v-text-field
                label="*Huisnr."
                v-model="detailMixin.element.algemeneGegevens.huisnummer"
                :rules="[validationMixin.rules.required]" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
              <control-postcode
                label="*Gemeente"
                required
                v-model="detailMixin.element.algemeneGegevens.idPostcode"/>
          </v-col>
          </v-row>
        <h3 class="secondary--text my-3">
          Kadastrale aanduiding
        </h3>
          <v-row class="pb-0">
            <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  label="Afdeling"
                  v-model="detailMixin.element.algemeneGegevens.kadastraleAfdeling" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  label="Sectie"
                  v-model="detailMixin.element.algemeneGegevens.kadastraleSectie" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  label="Nummers"
                  v-model="detailMixin.element.algemeneGegevens.kadastraleNummers"
                />
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col cols="12" sm="6" lg="3">
                <control-number
                  prefix="€"
                  label="Kadastraal inkomen (KI)"
                  v-model="detailMixin.element.algemeneGegevens.kadastraalInkomen" />
            </v-col>
        </v-row>

         <v-divider class="my-5"></v-divider>

        <h3 class="secondary--text my-3">Kaarten</h3>
        <geo-map
          :gemeente="GetSelectedPostcode()"
          :huisnummer="detailMixin.element.algemeneGegevens.huisnummer"
          :sliderZoom1="detailMixin.element.algemeneGegevens.zoomKaart1"
          :sliderZoom2="detailMixin.element.algemeneGegevens.zoomKaart2"
          :sliderZoom3="detailMixin.element.algemeneGegevens.zoomKaart3"
          :sliderZoom4="detailMixin.element.algemeneGegevens.zoomKaart4"
          :sliderZoom5="detailMixin.element.algemeneGegevens.zoomKaart5"
          :sliderZoom6="detailMixin.element.algemeneGegevens.zoomKaart6"
          :straat="detailMixin.element.algemeneGegevens.straat"
          @zoom="ChangeZoomMaps($event)"
        />

         <v-divider class="my-5"></v-divider>

        <h3 class="secondary--text my-3">Bijlage</h3>
        <v-row class="pb-0">
          <v-col cols="12" md="6">
            <control-file-input-drag-and-drop v-model="detailMixin.element.files" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
          </v-col>
        </v-row>
      </div>
    </v-form>
</template>

<script>
import errorMixin from '../../mixins/errorMixin'
import ControlFileInputDragAndDrop from '@/components/ControlFileInputDragAndDrop.vue'
import ControlPostcode from '@/components/ControlPostcode.vue'
import ControlSelect from '@/components/ControlSelect.vue'
import ControlDate from '@/components/ControlDate.vue'
import ControlNumber from '@/components/ControlNumber.vue'
import schattingService from '@/services/schattingService'
import geoService from '@/services/geoService'
import codeService from '@/services/codeService'
import postService from '@/services/postService'
import validationMixin from '@/mixins/validationMixin'
import commonMixin from '@/mixins/commonMixin'
import blobMixin from '@/mixins/blobMixin'
import detailMixin from '@/mixins/detailMixin'
import formatMixin from '@/mixins/formatMixin'
import GeoMap from '@/components/GeoMap.vue'
import authenticationMixin from '@/mixins/authenticationMixin'
import authenticationService from '../../services/authenticationService'
import OptionList from '@/components/OptionList.vue'

export default {
  mixins: [
    validationMixin,
    commonMixin,
    errorMixin,
    detailMixin,
    formatMixin,
    authenticationMixin,
    blobMixin
  ],
  components: {
    ControlDate,
    ControlSelect,
    ControlNumber,
    ControlFileInputDragAndDrop,
    ControlPostcode,
    GeoMap,
    OptionList
  },
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      aarden: [
        'Woning ',
        'Appartement',
        'Studio',
        'Opbrengsteigendom ',
        'Commercieel',
        'Kantoor',
        'KMO',
        'Industrieel',
        'Villa',
        'Bouwgrond ',
        'Landbouwgrond',
        'Natuurgebied',
        'Bosgrond',
        'Boerderij',
        'Projectgrond',
        'Garage'
      ],
      banken: [],
      bebouwingen: [],
      ext: '',
      gebruikers: [],
      opdrachtTemplates: [
        'Kredietaanvraag / Hypothecair krediet',
        'Aangifte nalatenschap conform Vlabel',
        'Schenking / Successie / Verdeling conform Vlabel',
        'Informatief',
        'Vereffening verdeling',
        'Inbreng in vennootschap',
        'Gerechtelijk procedure',
        'Openbare besturen'
      ],
      postcodes: null,
      referentieType: 'c1c7f6af-040d-4592-a5b9-b1cc0c9a7f37',
      selectedPostcode: null
    }
  },
  mounted () {
    this.SetVariables()
    geoService.Init(this)
    schattingService.Init(this)
    codeService.Init(this)
    postService.Init(this)
    this.LoadEverything()
  },
  watch: {
    idSchatting () {
      this.GetSchatting()
    },
    mapScale () {
      if (this.mapimages !== null) {
        this.mapimage = this.mapimages.find(element => element.scale === this.mapScale).base64
        this.mapimage2 = this.mapimages2.find(element => element.scale === this.mapScale).base64
      }
    },
    visible () {
      if (!this.visible) {
        if (this.DetailMixin_Dirty) {
          this.DetailMixin_Save()
        }
      }

      if (this.visible && !this.DetailMixin_Revertable) {
        this.GetSchatting()
      }
    }
  },
  methods: {
    ChangeZoomMaps (zoom) {
      for (let i = 1; i <= 4; i++) {
        this.detailMixin.element.algemeneGegevens[`zoomKaart${i}`] = zoom[`zoom${i}`]
      }
    },
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    GetMap (type) {
      switch (type) {
        case 1:
          return `https://maps.geopunt.be/resources/apps/Minimaps_interface/index.html?lang=nl&adres=${this.detailMixin.element.algemeneGegevens.straat}%20${this.detailMixin.element.algemeneGegevens.huisnummer}%20${this.detailMixin.element.algemeneGegevens.gemeente}&lod=10&maximize=1&basemap=grb_grijs`
        case 2:
          return `https://maps.geopunt.be/resources/apps/Minimaps_interface/index.html?lang=nl&adres=${this.detailMixin.element.algemeneGegevens.straat}%20${this.detailMixin.element.algemeneGegevens.huisnummer}%20${this.detailMixin.element.algemeneGegevens.gemeente}&lod=10&maximize=1&basemap=grb`
        case 3:
          return `https://maps.geopunt.be/resources/apps/Gewestplan_app/index.html?lang=nl&adres=${this.detailMixin.element.algemeneGegevens.straat}%20${this.detailMixin.element.algemeneGegevens.huisnummer}%20${this.detailMixin.element.algemeneGegevens.gemeente}&lod=10&maximize=1&basemap=grb_grijs`
      }
    },
    async LoadEverything () {
      await this.GetBanken()
      await this.GetBebouwingen()
      await this.GetGebruikers()
      await this.GetPosten()
      // await this.GetAardenGoed()
    },
    async GetSchatting () {
      this.CommonMixin_Load(this.$options.name, true)

      const id = this.DetailMixin_GetId()

      if (id) {
        const responseSchatting = await schattingService.GetSchattingById(id)
        const element = {
          schatting: {},
          algemeneGegevens: {},
          files: []
        }

        element.files = await this.BlobMixin_GetFiles(this.idSchatting, this.referentieType)

        if (responseSchatting && (responseSchatting.data || responseSchatting.data !== '')) {
          element.schatting = this.CommonMixin_Clone(responseSchatting.data)
          this.$forceUpdate()
        }

        const responseAlgemeneGegevens = await schattingService.GetAlgemeneGegevensByIdSchatting(id)

        if (responseAlgemeneGegevens && (responseAlgemeneGegevens.data || responseAlgemeneGegevens.data !== '')) {
          element.algemeneGegevens = this.CommonMixin_Clone(responseAlgemeneGegevens.data)
          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    ConvertFromStringToDateString (date) {
      const datePieces = date.split('/')
      return (`${datePieces[2]}-${datePieces[1]}-${datePieces[0]}`)
    },
    async GetBanken () {
      const response = await codeService.GetBanken()
      if (response && response.data) {
        const data = response.data
        this.banken = data
      }
    },
    async GetBebouwingen () {
      const response = await codeService.GetBebouwingen()
      if (response && response.data) {
        const data = response.data
        this.bebouwingen = data
      }
    },
    SetPostcodeOntlener: function (value) {
      this.idPostcodeOntlener = value
    },
    async GetGebruikers () {
      const response = await authenticationService.GetGebruikersList()
      if (response && response.data) {
        const data = response.data
        this.gebruikers = data
      }
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formAlgemeen.validate
      this.detailMixin.functions.save = this.Save
    },
    async Save (element) {
      var schatting = await schattingService.UpdateSchatting(element.schatting)
      var schattingAlgemeneGegevens = await schattingService.UpdateSchattingAlgemeneGegevens(element.algemeneGegevens)
      await this.BlobMixin_SaveFiles(element.files, this.idSchatting, this.referentieType)
      var schattingData = null
      var schattingAlgemeneGegevensData = null
      if (schatting && schatting.data) {
        schattingData = schatting.data
      }
      if (schattingAlgemeneGegevens && schattingAlgemeneGegevens.data) {
        schattingAlgemeneGegevensData = schattingAlgemeneGegevens.data
      }
      return {
        data: {
          schatting: schattingData,
          algemeneGegevens: schattingAlgemeneGegevensData
        }
      }
    },
    GetSelectedPostcode () {
      if (this.postcodes !== null) {
        var selectedPostcode = this.postcodes.find(x => x.idPostcode === this.detailMixin.element.algemeneGegevens.idPostcode)
        if (selectedPostcode && selectedPostcode.tekst) {
          this.selectedPostcode = selectedPostcode.tekst
          return this.selectedPostcode
        }
      }
    },
    async GetPosten () {
      const response = await postService.GetPosten()
      if (response && response.data) {
        const data = response.data
        this.postcodes = data
      }
    },
    Validate () {
      this.$refs.formAlgemeen.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
