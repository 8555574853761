<template>
  <v-form class="my-2"
    v-model="detailMixin.valid"
    ref="formWaarde">
    <div
      v-if="detailMixin.element.waarde"
      :class="DetailMixin_Dirty ? 'dirtyForm' : DetailMixin_Revertable && !detailMixin.element.isNew ? 'editForm' : ''"
      >
      <h2 class="primary--text">Berekening van de wezenlijke waarde</h2>
      <!-- <h3 class="secondary--text my-3">Geschatte kostprijs (obv aannemingsprijzen) - (totale kostprijs nieuw: € 0.00)</h3>
      <v-row class="pb-0">
        <v-col cols="6" sm="4" lg="2">
          <control-number :decimals="2" label="Aantal m²" suffix="m²" v-model="detailMixin.element.waarde.vierkanteMeters"/>
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <control-number :decimals="2" label="Kost / m²" prefix="€" v-model="detailMixin.element.waarde.kostPerVierkanteMeter"/>
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <control-number :decimals="2" label="Vetustiteit"  suffix="%" v-model="detailMixin.element.waarde.vetustiteit"/>
        </v-col>
      </v-row>

      <v-row class="pb-0 my-5">
        <v-col cols="6" sm="4" lg="2">
          <control-number :decimals="2" label="Afschrijvingen" prefix="€" v-model="detailMixin.element.waarde.afschrijvingen"/>
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <control-number :decimals="2" label="Grondwaarde" prefix="€" v-model="detailMixin.element.waarde.grondwaarde"/>
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <control-number :decimals="2" label="Nieuwbouwwaarde" prefix="€" v-model="detailMixin.element.waarde.nieuwbouwwaarde"/>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider> -->

      <h3 class="secondary--text my-3">Verkoopwaarde</h3>
      <v-row class="pb-0 mb-5">
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Onderhandse verkoopwaarde</h4>
          <v-row class="pb-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Voor werken" prefix="€" v-model="detailMixin.element.waarde.verkoopwaardeOnderhandsVoorWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Na werken" prefix="€" v-model="detailMixin.element.waarde.verkoopwaardeOnderhandsNaWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Privé" suffix="%" v-model="detailMixin.element.waarde.verkoopwaardeOnderhandsPrive"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Beroeps" suffix="%" v-model="detailMixin.element.waarde.verkoopwaardeOnderhandsBeroeps"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Gedwongen verkoopwaarde</h4>
          <v-row class="pb-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Voor werken" prefix="€" v-model="detailMixin.element.waarde.verkoopwaardeGedwongenVoorWerken"/>
            </v-col>
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Na werken" prefix="€" v-model="detailMixin.element.waarde.verkoopwaardeGedwongenNaWerken"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pb-0 mb-5">
        <v-col cols="12" md="5" lg="3">
          <h4 class="secondary--text my-3">Gecommuniceerde aankoopprijs</h4>
          <v-row class="pb-0">
            <v-col cols="6" md="5">
              <control-number :decimals="2" label="Bedrag" prefix="€" v-model="detailMixin.element.waarde.geschatteWaarde"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">SWOT-analyse</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Sterktes" auto-grow outlined v-model="detailMixin.element.waarde.sterktes"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Zwaktes" auto-grow outlined v-model="detailMixin.element.waarde.zwaktes"/>
        </v-col>
      </v-row>
      <v-row class="pb-0">
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Opportuniteiten" auto-grow outlined v-model="detailMixin.element.waarde.opportuniteiten"/>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-textarea label="Bedreigingen" auto-grow outlined v-model="detailMixin.element.waarde.bedreigingen"/>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <h3 class="secondary--text my-3">Bijlage</h3>
      <v-row class="pb-0">
        <v-col cols="12" md="6">
          <control-file-input-drag-and-drop v-model="detailMixin.element.files" @download="Download($event)" :accept="['.png', '.jpg', '.jpeg', '.gif']"/>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import ControlNumber from '../ControlNumber.vue'
import ControlFileInputDragAndDrop from '../ControlFileInputDragAndDrop.vue'
import schattingService from '../../services/schattingService'
import commonMixin from '../../mixins/commonMixin'
import errorMixin from '../../mixins/errorMixin'
import detailMixin from '../../mixins/detailMixin'
import blobMixin from '../../mixins/blobMixin'

export default {
  mixins: [
    commonMixin,
    errorMixin,
    detailMixin,
    blobMixin
  ],
  components: {
    ControlNumber,
    ControlFileInputDragAndDrop
  },
  props: {
    idSchatting: {
      default: null,
      type: String
    },
    visible: Boolean
  },
  data () {
    return {
      referentieType: '5c03455b-88b2-45fe-8309-dd8c81aaec77'
    }
  },
  mounted () {
    schattingService.Init(this)
  },
  watch: {
    async visible () {
      if (this.visible) {
        await this.SetVariables()
        await this.SetFields()
      } else if (this.DetailMixin_Dirty) {
        this.DetailMixin_Save()
      }
    }
  },
  methods: {
    Download (blob) {
      const blobFormatted = this.CommonMixin_Clone(blob)
      blobFormatted.bestandsnaam = blob.name
      this.BlobMixin_Download(blobFormatted)
    },
    async SetFields () {
      this.CommonMixin_Load(this.$options.name, true)
      const id = this.DetailMixin_GetId()

      if (id) {
        var element = {
          waarde: {},
          files: []
        }

        element.files = await this.BlobMixin_GetFiles(this.idSchatting, this.referentieType)

        const responseWaarde = await schattingService.GetWaardeByIdSchatting(id)
        if (responseWaarde && (responseWaarde.data || responseWaarde.data !== '')) {
          element.waarde = this.CommonMixin_Clone(responseWaarde.data)
          this.$forceUpdate()
        }

        this.detailMixin.element = this.CommonMixin_Clone(element)

        this.DetailMixin_InitDirtyWatch()
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    SetVariables () {
      this.detailMixin.key = 'id'
      this.detailMixin.rightsNeeded = ['admin', 'gebruiker']
      this.detailMixin.functions.validate = this.$refs.formWaarde.validate
      this.detailMixin.functions.save = this.save
    },
    async save (element) {
      var waarde = await schattingService.UpdateSchattingWaarde(element.waarde)
      await this.BlobMixin_SaveFiles(element.files, this.idSchatting, this.referentieType)
      var waardeData = null
      if (waarde && waarde.data) {
        waardeData = waarde.data
      }
      return {
        data: {
          waarde: waardeData
        }
      }
    },
    Validate () {
      this.$refs.formWaarde.validate()
      return this.detailMixin.valid
    }
  }
}
</script>
