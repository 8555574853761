<template>
  <v-row
    class="px-1 pl-2 pt-5"
  >
    <v-col
      v-if="!adding"
      class="px-0 pr-1 pt-1"
      cols="12"
      :lg="LgCols"
    >
      <v-btn
        v-if="dirty"
        block
        color="primary"
        small
        :disabled="disabled"
        @click="$emit('revertChanges')"
      >
        Annuleren
      </v-btn>
      <v-btn
        v-else
        block
        color="primary"
        small
        @click="$emit('close')"
      >
        Annuleren
      </v-btn>
    </v-col>
    <v-col
      v-if="edit && !adding"
      class="px-0 pr-1 pt-1"
      cols="12"
      :lg="LgCols"
    >
      <v-btn
        block
        color="primary"
        small
        :disabled="disabled || !deletable"
        @click="$emit('delete')"
      >
        Verwijderen
      </v-btn>
    </v-col>
    <v-col
      class="px-0 pr-1 pt-1"
      cols="12"
      :lg="LgCols"
    >
      <v-btn
        v-if="adding"
        block
        color="primary"
        small
        @click="$emit('close')"
      >
        Annuleren
      </v-btn>
      <v-btn
        v-else
        block
        color="primary"
        small
        :disabled="!(valid && dirty)"
        @click="$emit('close')"
      >
        Opslaan
      </v-btn>
    </v-col>
    <v-col
      v-if="edit && adding"
      class="px-0 pr-1 pt-1"
      cols="12"
      :lg="LgCols"
    >
      <v-btn
        block
        color="primary"
        small
        :disabled="disabled || !valid"
        @click="$emit('add')"
      >
        Opslaan
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ControlEditCommandsTable',
  props: {
    adding: Boolean,
    deletable: {
      default: true,
      type: Boolean
    },
    dirty: Boolean,
    disabled: Boolean,
    edit: Boolean,
    valid: Boolean
  },
  computed: {
    LgCols () {
      return Math.ceil(12 / this.VisibleButtons)
    },
    VisibleButtons () {
      let count = 1

      if (!this.adding) count++
      if (this.edit) count++

      return count
    }
  }
}
</script>
