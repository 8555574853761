import textMixin from '@/mixins/textMixin'

export default {
  mixins: [
    textMixin
  ],
  data () {
    return {
      errorMixin: {
        errors: [
          {
            code: 0,
            description: 'Unknown',
            redirect: false,
            text: 'Er is iets misgelopen.'
          },
          {
            code: 401,
            description: 'Unauthorized',
            redirect: true,
            text: 'U heeft geen toegang tot deze pagina of gegevens.'
          },
          {
            code: 404,
            description: 'Not Found',
            redirect: true,
            text: 'De pagina of gegevens die u probeert op te vragen bestaan niet.'
          },
          {
            code: 417,
            description: 'ExpectationFailed',
            redirect: false,
            text: 'De ingegeven informatie voldeed niet aan de verwachtingen.'
          },
          {
            code: 500,
            description: 'Internal Server Error',
            redirect: false,
            text: 'De server gaf een interne error en kon het verzoek niet verwerken.'
          }
        ]
      }
    }
  },
  methods: {
    ErrorMixin_GetError (code) {
      return this.errorMixin.errors.find(error => error.code.toString() === code.toString()) ?? this.errorMixin.errors.find(error => error.code === 0)
    },
    ErrorMixin_HandleError (error) {
      if (error.response) {
        const errorInformation = this.ErrorMixin_GetError(error.response.status)

        if (errorInformation.redirect) {
          this.$router.push({ path: 'Error', query: { code: error.response.status } })
        } else {
          let message = errorInformation.text

          if (errorInformation.code === 417) {
            message = error.response.data != null && error.response.data.toString().trim().length > 0 ? error.response.data : this.textMixin.messages.error
          }

          this.CommonMixin_Snackbar(message, 'error')
        }
      } else {
        if (!this.CommonMixin_Error) {
          this.$store.state.error = true
          console.error(this.textMixin.messages.error, 'error')
        }
      }
    }
  }
}
