<template>
  <v-container class="px-3" fluid tag="section">
    <v-row>
      <v-col class="center" cols="12" lg="12">
        <div>
          <v-stepper v-model="stepperStep" alt-labels style="overflow: visible">
            <v-stepper-header  style="position: sticky; top: 0; background-color: white; z-index: 2">
              <template
                v-for="(step, index) in steps"
              >
                <v-divider
                  v-if="index > 0"
                  :key="`divider-${index}`"
                />
                <v-stepper-step
                  :key="`stepper-step-${index}`"
                  editable
                  :step="step.step"
                >
                  {{ step.title }}
                </v-stepper-step>
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(step, index) in steps"
                :key="index"
                :step="step.step"
              >
                <component
                  :is="step.component"
                  :idSchatting="idSchatting"
                  :ref="step.ref"
                  :visible="stepperStep == step.step"
                  @dirty="step.dirty = $event"
                  @edit="step.edit = $event"
                />
              </v-stepper-content>
            </v-stepper-items>

            <v-row>
              <v-col class="ma-5">
                <v-btn v-if="stepperStep>1" color="primary" @click="previousStep()">
                  Vorige
                </v-btn>
              </v-col>
              <v-col class="ma-5 text-right">
                <v-btn v-if="stepperStep<8" color="primary" @click="nextStep()">
                  Volgende
                </v-btn>
                <v-btn v-else color="primary" @click="Save()">
                  Opslaan
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlgemeneGegevens from './SchattingSteps/AlgemeneGegevens.vue'
import Afmetingen from './SchattingSteps/Afmetingen.vue'
import BeschrijvingPand from './SchattingSteps/BeschrijvingPand.vue'
import Bouwmaterialen from './SchattingSteps/Bouwmaterialen.vue'
import NutsvoorzieningenEnComfort from './SchattingSteps/NutsvoorzieningenEnComfort.vue'
import BijzondereElementen from './SchattingSteps/BijzondereElementen.vue'
import Vergelijkingspunten from './SchattingSteps/Vergelijkingspunten.vue'
import Waarde from './SchattingSteps/Waarde.vue'
import textMixin from '../mixins/textMixin'
import commonMixin from '../mixins/commonMixin'

export default {
  components: {
    AlgemeneGegevens,
    Afmetingen,
    BeschrijvingPand,
    Bouwmaterialen,
    NutsvoorzieningenEnComfort,
    BijzondereElementen,
    Waarde,
    Vergelijkingspunten
  },
  mixins: [
    textMixin,
    commonMixin
  ],
  name: 'Schatting',
  data () {
    return {
      stepperStep: 1,
      idSchatting: null,
      steps: [
        {
          step: 1,
          component: 'algemene-gegevens',
          dirty: false,
          edit: false,
          ref: 'AlgemeneGegevens',
          title: 'Algemene gegevens',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 2,
          component: 'afmetingen',
          dirty: false,
          edit: false,
          ref: 'Afmetingen',
          title: 'Afmetingen',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 3,
          component: 'beschrijving-pand',
          dirty: false,
          edit: false,
          ref: 'BeschrijvingPand',
          title: 'Beschrijvingen van het pand',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 4,
          component: 'bouwmaterialen',
          dirty: false,
          edit: false,
          ref: 'Bouwmaterialen',
          title: 'Bouwmaterialen',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 5,
          component: 'nutsvoorzieningen-en-comfort',
          dirty: false,
          edit: false,
          ref: 'NutsvoorzieningenEnComfort',
          title: 'Nutsvoorzieningen en comfort',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 6,
          component: 'bijzondere-elementen',
          dirty: false,
          edit: false,
          ref: 'BijzondereElementen',
          title: 'Bijzondere elementen',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 7,
          component: 'waarde',
          dirty: false,
          edit: false,
          ref: 'Waarde',
          title: 'Waarde',
          types: [
            'admin',
            'gebruiker'
          ]
        },
        {
          step: 8,
          component: 'vergelijkingspunten',
          dirty: false,
          edit: false,
          ref: 'Vergelijkingspunten',
          title: 'Vergelijkingspunten',
          types: [
            'admin',
            'gebruiker'
          ]
        }
      ]
    }
  },
  mounted () {
    this.getSchatting()
  },
  computed: {
    CurrentStepData () {
      return this.GetStep(this.stepperStep)
    },
    CurrentStepComponent () {
      return this.$refs[this.CurrentStepData.ref][0]
    },
    DirtyAnywhere () {
      return this.DetailMixin_Dirty || this.steps.some(step => step.dirty)
    },
    EditingAnywhere () {
      return this.detailMixin.edit || this.steps.some(step => step.edit)
    }
  },
  methods: {
    GetStep (stepNumber) {
      return this.steps.find(step => step.step === stepNumber)
    },
    async getSchatting () {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        this.idSchatting = urlParams.get('id')
      } else if (!urlParams.has('new')) {
        this.$router.push({ path: '/' })
      }
    },
    previousStep () {
      if (this.ValidateCurrentStep()) {
        this.Save()
        this.stepperStep = Number(this.stepperStep) - 1
      } else {
        this.ShowValidationError()
      }
    },
    nextStep () {
      if (this.ValidateCurrentStep()) {
        this.Save()
        this.stepperStep = Number(this.stepperStep) + 1
      } else {
        this.ShowValidationError()
      }
    },
    async Leave (next) {
      if (this.CommonMixin_Confirmation(this.DirtyAnywhere, 'Er zijn nog niet opgeslagen veranderingen. Wilt u doorgaan zonder op te slaan?', true)) {
        if (this.DirtyAnywhere && (!this.CurrentStepData.dirty || this.ValidateCurrentStep())) {
          this.steps.filter(step => step.dirty).forEach(step => {
            this.$refs[step.ref][0].DetailMixin_Save()
          })
        }

        next()
      }
    },
    async Save () {
      if (this.DirtyAnywhere) {
        if (this.steps[this.steps.length - 1].dirty) {
          this.steps.filter(step => step.dirty).forEach(step => {
            this.$refs[step.ref][0].DetailMixin_Save()
          })
        }
      }
    },
    ShowValidationError () {
      this.CommonMixin_Snackbar('Gelieve eerst alle velden correct in te vullen', 'error')
    },
    ValidateCurrentStep () {
      return this.CurrentStepComponent.Validate()
    }
  }
}

</script>

<style scoped>
.v-form p {
  margin: 0;
}

.drag-and-drop-files {
  width: 100%;
  height: 200px;
  background-color: lightgray;
}

</style>
