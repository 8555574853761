<template>
  <div
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      v-if="!readonly && !disabled && (hovering || focus)"
      v-model="time"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      ref="control"
      :rules="Rule"
      type="time"
      @blur="focus = false"
      @change="$emit('change', $event)"
      @focus="focus = true"
    />
    <v-text-field
      v-if="readonly || disabled || !(hovering || focus) || focusWrong"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      :rules="Rule"
      :value="TimeText"
      @focus="FocusWrong()"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ControlTime',
  props: {
    dense: Boolean,
    disabled: Boolean,
    format: {
      default: 'YYYY-MM-DDTHH:mm:ss',
      type: String
    },
    label: String,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    value: String
  },
  data () {
    return {
      date: null,
      defaultDate: null,
      focus: false,
      focusWrong: false,
      hovering: false,
      ignore: false,
      requiredRule: value => !!value || this.requiredText,
      time: null
    }
  },
  mounted () {
    this.FormatData()
  },
  computed: {
    Rule () {
      if (this.required) {
        return [this.requiredRule]
      } else {
        return []
      }
    },
    TimeText () {
      return this.time ? this.time : ''
    }
  },
  watch: {
    time () {
      this.Emit()
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      if (this.ignore && !this.disabled) {
        this.ignore = false
      } else {
        this.FormatData()
      }
    }
  },
  methods: {
    Emit () {
      this.ignore = true

      if (this.time && this.time.trim() !== '') {
        if (!this.date || this.date.trim() !== '') {
          this.date = this.defaultDate ?? new Date().toISOString().split('T')[0]
        }

        this.$emit('input', moment(`${this.date}T${this.time}:00`, 'YYYY-MM-DDTHH:mm:ss').format(this.format))
      } else {
        this.$emit('input', null)
      }
    },
    FocusWrong () {
      this.focusWrong = true
      this.focus = true

      this.$nextTick(() => {
        this.$refs.control.focus()
        this.focusWrong = false
      })
    },
    FormatData () {
      if (this.value) {
        this.date = moment(this.value, this.format).format('YYYY-MM-DD')
        this.time = moment(this.value, this.format).format('HH:mm')
        this.defaultDate = this.defaultDate ?? this.date
      } else {
        this.date = null
        this.time = null
      }
    },
    MouseLeave () {
      this.hovering = false
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      this.$emit('mouseover')
    }
  }
}
</script>
