<template>
  <div>
    <div
      v-for="(snackbar, index) in Snackbars"
      :key="index"
      class="clickAction"
      @click="Hide(snackbar)"
    >
      <v-snackbar
        v-model="snackbar.show"
        right
        timeout="-1"
        :color="snackbar.color"
        :style="`bottom: ${index * 60 + 8}px`"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSnackbarStack',
  data () {
    return {
      running: false
    }
  },
  computed: {
    Snackbars () {
      return this.$store.state.snackbars.filter(snackbar => snackbar.show)
    }
  },
  watch: {
    Snackbars (newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        const snackbar = newValue[newValue.length - 1]
        snackbar.interval = setInterval(() => this.Timeout(snackbar), 1000)
      }
    }
  },
  methods: {
    Hide (snackbar) {
      snackbar.show = false
    },
    Timeout (snackbar) {
      if (snackbar && snackbar.timeout > 0) {
        snackbar.timeout--

        if (snackbar.timeout <= 0) {
          this.Hide(snackbar)
          clearInterval(snackbar.interval)
        }
      } else if (snackbar) {
        clearInterval(snackbar.interval)
      }
    }
  }
}
</script>
