<template>
  <error-detail />
</template>

<script>
import ErrorDetail from '../components/ErrorDetail.vue'

export default {
  name: 'ErrorDetailView',
  components: {
    ErrorDetail
  }
}
</script>
