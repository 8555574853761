<template>
  <div
    :style="edit ? {'cursor': 'pointer'} : {}"
  >
    <v-icon
      v-show="showAddPicture && edit"
      id="addPicture"
      size="40"
    >
      mdi-image
    </v-icon>
    <v-btn
      v-if="value[blobDataStringProperty] && edit"
      class="primary"
      :disabled="disabled"
      id="deletePicture"
      small
      @click="DeletePicture()"
    >
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn>
    <img
      class="center"
      :class="edit ? 'hoverDark' : ''"
      id="ControlPictureImage"
      :src="value == null || value[blobDataStringProperty] == null ? picturePlaceholder : value[blobDataStringProperty]"
      :style="maxHeight == null ? {} : {'max-height' : `${maxHeight}px`}"
      @click="ClickPicture()"
      @mouseenter="ShowAddPicture(true)"
      @mouseleave="ShowAddPicture(false)"
    >
    <input
      accept="image/*"
      ref="inputPicture"
      type="file"
      v-show="false"
      @change="PictureUploaded($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ControlPicture',
  props: {
    blobDataStringProperty: String,
    deleteConfirmationText: {
      default: 'Are you sure you want to delete this picture?',
      type: String
    },
    disabled: Boolean,
    edit: Boolean,
    maxHeight: Number,
    picturePlaceholder: String,
    value: Object
  },
  data () {
    return {
      showAddPicture: false
    }
  },
  methods: {
    ClickPicture () {
      if (this.edit) {
        this.$refs.inputPicture.value = null
        this.$refs.inputPicture.click()
      }
    },
    DeletePicture () {
      if (window.confirm(this.deleteConfirmationText)) {
        this.$refs.inputPicture.value = null
        this.$emit('delete')
      }
    },
    PictureUploaded (value) {
      this.$emit('upload', value)
    },
    SetAddPictureLocation () {
      const photo = document.getElementById('ControlPictureImage')
      const overlay = document.getElementById('addPicture')

      overlay.style.left = `${photo.getBoundingClientRect().width / 2 - 8}px`
      overlay.style.position = 'absolute'
      overlay.style.top = `${photo.getBoundingClientRect().height / 2 - 20}px`
    },
    ShowAddPicture (show) {
      this.SetAddPictureLocation()
      this.showAddPicture = show
    }
  }
}
</script>

<style scoped>
  .hoverDark:hover {
    opacity: 0.3;
  }

  #deletePicture {
    left: 20px;
    max-width: 30px !important;
    min-width: 30px !important;
    position: absolute;
    top: 8px;
    z-index: 1;
  }
</style>
