<template>
  <v-dialog
    v-model="loading"
    content-class="dialogLoader"
    persistent
  >
    <v-progress-circular
      class="loader"
      color="primary"
      indeterminate
      size="100"
      width="10"
    ></v-progress-circular>
  </v-dialog>
</template>

<script>
export default {
  name: 'TheLoader',
  data () {
    return {
      interval: null,
      loading: false,
      stop: false
    }
  },
  computed: {
    Loading () {
      return this.$store.state.loading && this.$store.state.loading.length > 0
    }
  },
  watch: {
    Loading (value) {
      if (value) {
        this.loading = true
        this.stop = false
        this.interval = setInterval(() => this.SetStop(), 500)
      } else if (this.stop) {
        this.loading = false
      }
    }
  },
  methods: {
    SetStop () {
      this.stop = true
      clearInterval(this.interval)

      if (!this.Loading) {
        this.loading = false
      }
    }
  }
}
</script>
