import blobService from '@/services/blobService'

function resizeImage (file, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = file

    img.onload = () => {
      let width = img.width
      let height = img.height

      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }

      if (height > maxHeight) {
        width *= maxHeight / height
        height = maxHeight
      }

      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)

      canvas.toBlob((resizedBlob) => {
        URL.revokeObjectURL(img.src)
        resolve(resizedBlob)
      }, file.type, 0.90)
    }

    img.onerror = (error) => {
      reject(error)
    }
  })
}

function blobToBase64 (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export default {
  data () {
    return {
      blobMixin: {
        status: {
          unchanged: 0,
          new: 1,
          updated: 2,
          deleted: 3
        }
      }
    }
  },
  mounted () {
    blobService.Init(this)
  },
  computed: {
  },
  methods: {
    async BlobMixin_Download (blob) {
      if (blob && !blob.isNew && !blob.isEdited) {
        const resp = await blobService.Download(blob.idBlob)

        if (resp && (resp.data || resp.data !== '')) {
          this.BlobMixin_DownloadAction(resp.data, blob.bestandsnaam)
        }
      }
    },
    async BlobMixin_SaveBlobs (blobs) {
      if (blobs) {
        const resp = await blobService.Save(blobs)

        if (resp && (resp.data || resp.data !== '')) {
          return resp.data
        }
      }
      return null
    },
    async BlobMixin_DeleteBlobById (idBlob) {
      var blobs = [{
        idBlob: idBlob,
        blobStatus: this.blobMixin.status.deleted
      }]

      return this.BlobMixin_SaveBlobs(blobs)
    },
    async BlobMixin_GetFiles (idKoppeling, type, subtype = null) {
      var responseFiles = await this.BlobMixin_GetBlobs({
        idKoppeling: idKoppeling,
        type: type,
        subtype: subtype
      })

      var files = []

      if (responseFiles != null) {
        responseFiles.forEach(file => {
          files.push({
            idBlob: file.idBlob,
            status: file.blobStatus,
            name: file.bestandsnaam,
            size: file.size
          })
        })
      }
      return files
    },
    async BlobMixin_GetBlobs (blobs) {
      if (blobs) {
        const resp = await blobService.GetBySearchCriteria(blobs)

        if (resp && (resp.data || resp.data !== '')) {
          return resp.data
        }
      }
      return null
    },
    async BlobMixin_DownloadAction (fileStream, fileName) {
      const file = window.URL.createObjectURL(fileStream)
      const docUrl = document.createElement('a')
      docUrl.href = file
      docUrl.setAttribute('download', fileName.replaceAll('"', ''))
      docUrl.click()
    },
    async BlobMixin_SaveFiles (files, idKoppeling, type, subtype = null) {
      var blobs = []

      if (!files || files.length === 0) return

      for await (var file of files) {
        if (file.data && file.data.startsWith('data:image')) {
          var data = await resizeImage(file.data, 1500, 1500)
          file.data = await blobToBase64(data)
        }

        blobs.push({
          idBlob: file.idBlob,
          idKoppeling: idKoppeling,
          type: type,
          subtype: subtype,
          blobDataString: file.data,
          blobStatus: file.status,
          size: file.size,
          bestandsnaam: file.name
        })
      }

      var responseBlobs = await this.BlobMixin_SaveBlobs(blobs)
      if (!responseBlobs || responseBlobs.length === 0) {
        this.CommonMixin_Snackbar(this.textMixin.messages.saveFailedFiles, 'error')
        return null
      } else {
        return responseBlobs
      }
    }
  }
}
